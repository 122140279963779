@import 'variables';

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #09d3ac;
}

.services-item {
    /* border: 1px solid $mainColor; */
}

.services-item h5 {
    font-size: 1rem;
    /* color: black; */
}

.arrow-icon {
    color: black;
}

.list-body {
    text-align: left;
}

.list-body p {
    font-size: 1rem;
}

.list-body p FontAwesomeIcon {
    margin-left: 4px;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        background: white;
        border-radius: 5px;

    }

    .nav-link {
        border-right: 1px solid #fa3460
    }

    .img-fluid {
        max-width: 100%;
        height: auto;
        width: 100%;
    }

    body {
        background-color: $secondaryColor;
    }

    .navbar {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 0rem;
    }

    .desktop >.listProyectBenefit {

      /* flexbox setup */
      display: inline-block;
      width: 100%;
      background: #fff;

    }

    .desktop .itemProyectBenefits {
  
        width: calc(100% - 20px); 
        margin: 10px  auto;
        -webkit-transition: width 0.7s ease-out;
        transition: width 0.7s ease-out;
    }
}