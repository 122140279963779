@import 'variables';
@import 'bootstrap';

@font-face {
  font-family: 'ProximaNova';
  src: url("/assets/fonts/Proxima_Nova/ProximaNova-Semibold.otf") format("opentype");
}
body {
  margin: 0;
  font-family: 'ProximaNova';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //background-color:$secondaryColor;
   /* background-image: linear-gradient(to right, $secondaryColor, $secondaryColor, #343434, #161616); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .container {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
}
 */

.info-txt {
  color: grey;
  padding: 8px;
}


